// USA

// VW routes
export const VW = '/tnc/vw';
export const AUTHORIZATION_VW = '/tnc/vw/autopay';
export const PRIVACY_VW = '/tnc/vw/privacy';
export const ONLINE_ACCESS_VW = '/tnc/vw/OnlineAccess';
export const ACH_VW = '/tnc/vw/ach';
export const SMS_VW = '/tnc/vw/sms';
export const B2B_REPRESENTATIVE_BUSINESS_VW = '/tnc/vw/b2b-representative';

// Audi routes
export const AUDI = '/tnc/audi';
export const AUTHORIZATION_AUDI = '/tnc/audi/autopay';
export const PRIVACY_AUDI = '/tnc/audi/privacy';
export const ONLINE_ACCESS_AUDI = '/tnc/audi/OnlineAccess';
export const ACH_AUDI = '/tnc/audi/ach';
export const SMS_AUDI = '/tnc/audi/sms';
export const B2B_REPRESENTATIVE_BUSINESS_AUDI = '/tnc/audi/b2b-representative';

// Short URLs change...
// Audi routes
export const SHORT_AUDI = '/tnc';
export const SHORT_AUTHORIZATION_AUDI = '/tnc/autopay';
export const SHORT_PRIVACY_AUDI = '/tnc/privacy';
export const SHORT_ONLINE_ACCESS_AUDI = '/tnc/OnlineAccess';
export const SHORT_ACH_AUDI = '/tnc/ach';
export const DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK = '/dsi/oauth/callback';
export const DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK = '/dsi/signed/callback';
export const SHORT_SMS_AUDI = '/tnc/sms';
export const SHORT_B2B_REPRESENTATIVE_AUDI = '/tnc/b2b-representative';

// VW
export const SHORT_VW = '/tnc';
export const SHORT_AUTHORIZATION_VW = '/tnc/autopay';
export const SHORT_PRIVACY_VW = '/tnc/privacy';
export const SHORT_ONLINE_ACCESS_VW = '/tnc/OnlineAccess';
export const SHORT_ACH_VW = '/tnc/ach';
export const SHORT_SMS_VW = '/tnc/sms';
export const SHORT_B2B_REPRESENTATIVE_BUSINESS_VW = '/tnc/b2b-representative';

// CANADA

// VW routes
export const VW_CANADA = '/tnc/can/vw';
export const PRIVACY_VW_CANADA = '/tnc/can/vw/privacy';
export const ONLINE_ACCESS_VW_CANADA = '/tnc/can/vw/OnlineAccess';

// Audi routes
export const AUDI_CANADA = '/tnc/can/audi';
export const PRIVACY_AUDI_CANADA = '/tnc/can/audi/privacy';
export const ONLINE_ACCESS_AUDI_CANADA = '/tnc/can/audi/OnlineAccess';

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Brands, Countries, Domains } from './enums';
import * as routePaths from './RoutePaths';
import TermsAndCondition from './view/pages/terms-and-conditions/TermsAndCondition';
import DocuSignOAuthCallback from './view/pages/docu-sign-integration-callbacks/DocuSignIntegrationOAuthCallback';
import DocuSignSigningCallback from './view/pages/docu-sign-integration-callbacks/DocuSignIntegrationSignedCallback';

const SiteRoutes = () => {
  const domainName = window.location.host;
  if (Domains.AUDI.includes(domainName)) {
    return (
      <Routes>
        <Route path='/' element={<Navigate to={routePaths.SHORT_AUDI} />} />
        <Route
          path={routePaths.SHORT_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='default' />}
        />
        <Route
          path={routePaths.SHORT_AUTHORIZATION_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='Authorization' />}
        />
        <Route
          path={routePaths.SHORT_PRIVACY_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='privacy' />}
        />
        <Route
          path={routePaths.SHORT_ONLINE_ACCESS_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='online-access' />}
        />
        <Route
          path={routePaths.SHORT_ACH_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='ach' />}
        />
        <Route path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK} element={<DocuSignOAuthCallback />} />
        <Route path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK} element={<DocuSignSigningCallback />} />
        <Route
          path={routePaths.SHORT_SMS_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='sms' />}
        />
        <Route
          path={routePaths.SHORT_B2B_REPRESENTATIVE_AUDI}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='b2b-representative' />}
        />
      </Routes>
    );
  }

  if (Domains.VW.includes(domainName)) {
    return (
      <Routes>
        <Route path='/' element={<Navigate to={routePaths.SHORT_VW} />} />
        <Route
          path={routePaths.SHORT_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='default' />}
        />
        <Route
          path={routePaths.SHORT_AUTHORIZATION_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='Authorization' />}
        />
        <Route
          path={routePaths.SHORT_PRIVACY_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='privacy' />}
        />
        <Route
          path={routePaths.SHORT_ONLINE_ACCESS_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='online-access' />}
        />
        <Route
          path={routePaths.SHORT_ACH_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='ach' />}
        />
        <Route path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK} element={<DocuSignOAuthCallback />} />
        <Route path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK} element={<DocuSignSigningCallback />} />
        <Route
          path={routePaths.SHORT_SMS_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='sms' />}
        />
        <Route
          path={routePaths.SHORT_B2B_REPRESENTATIVE_BUSINESS_VW}
          element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='b2b-representative' />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path={routePaths.AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='default' />}
      />
      <Route
        path={routePaths.VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='default' />}
      />
      <Route
        path={routePaths.AUTHORIZATION_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='Authorization' />}
      />
      <Route
        path={routePaths.PRIVACY_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='privacy' />}
      />
      <Route
        path={routePaths.ONLINE_ACCESS_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='online-access' />}
      />
      <Route
        path={routePaths.ACH_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='ach' />}
      />
      <Route
        path={routePaths.SMS_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='sms' />}
      />
      <Route
        path={routePaths.B2B_REPRESENTATIVE_BUSINESS_AUDI}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='b2b-representative' />}
      />
      <Route
        path={routePaths.AUTHORIZATION_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='Authorization' />}
      />
      <Route
        path={routePaths.PRIVACY_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='privacy' />}
      />
      <Route
        path={routePaths.ONLINE_ACCESS_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='online-access' />}
      />
      <Route
        path={routePaths.ACH_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='ach' />}
      />
      <Route
        path={routePaths.SMS_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='sms' />}
      />
      <Route
        path={routePaths.B2B_REPRESENTATIVE_BUSINESS_VW}
        element={<TermsAndCondition country={Countries.USA} brand={Brands.VW} page='b2b-representative' />}
      />
      <Route
        path={routePaths.AUDI_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='default' />}
      />
      <Route
        path={routePaths.VW_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='default' />}
      />
      <Route
        path={routePaths.PRIVACY_AUDI_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='privacy' />}
      />
      <Route
        path={routePaths.ONLINE_ACCESS_AUDI_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='online-access' />}
      />
      <Route
        path={routePaths.PRIVACY_VW_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='privacy' />}
      />
      <Route
        path={routePaths.ONLINE_ACCESS_VW_CANADA}
        element={<TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='online-access' />}
      />
      <Route path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK} element={<DocuSignOAuthCallback />} />
      <Route path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK} element={<DocuSignSigningCallback />} />
      <Route path='/' element={<Navigate to={routePaths.AUDI} />} />
    </Routes>
  );
};

export default SiteRoutes;

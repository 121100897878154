import React from 'react';
import { NavLink } from 'react-router-dom';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import './SideBar.scss';
import { Brands, Countries, Domains } from '../../../enums';
import { BrandType, CountryType } from '../../../types';
import * as routePaths from '../../../RoutePaths';
import iconimage from '../../../assets/icons/external-link-small.svg';

const SideBar: React.FC<{ country: Countries; brand: Brands }> = (props) => {
  const { country, brand } = props;
  const myAudiLoginUrl = 'https://www.audiusa.com/us/web/en/myaudi/redirect.html';
  function getRoute(countryName: Countries, brandName: Brands, page: string): string {
    let route = routePaths.AUDI;
    const domainName = window.location.host;

    switch (countryName) {
      case Countries.Canada:
        if (brandName === Brands.AUDI && page === 'privacy') route = routePaths.PRIVACY_AUDI_CANADA;
        else if (brandName === Brands.AUDI && page === 'OnlineAccess') route = routePaths.ONLINE_ACCESS_AUDI_CANADA;
        else if (page === 'privacy') route = routePaths.PRIVACY_VW_CANADA;
        else if (page === 'OnlineAccess') route = routePaths.ONLINE_ACCESS_VW_CANADA;
        break;
      case Countries.USA:
        if (brandName === Brands.AUDI && page === 'privacy')
          route = Domains.AUDI.includes(domainName) ? routePaths.SHORT_PRIVACY_AUDI : routePaths.PRIVACY_AUDI;
        else if (brandName === Brands.AUDI && page === 'OnlineAccess')
          route = Domains.AUDI.includes(domainName)
            ? routePaths.SHORT_ONLINE_ACCESS_AUDI
            : routePaths.ONLINE_ACCESS_AUDI;
        else if (brandName === Brands.AUDI && page === 'ach')
          route = Domains.AUDI.includes(domainName) ? routePaths.SHORT_ACH_AUDI : routePaths.ACH_AUDI;
        else if (brandName === Brands.AUDI && page === 'sms')
          route = Domains.AUDI.includes(domainName) ? routePaths.SHORT_SMS_AUDI : routePaths.SMS_AUDI;
        else if (brandName === Brands.AUDI && page === 'autopay')
          route = Domains.AUDI.includes(domainName)
            ? routePaths.SHORT_AUTHORIZATION_AUDI
            : routePaths.AUTHORIZATION_AUDI;
        else if (brandName === Brands.AUDI && page === 'myAudi') route = myAudiLoginUrl;
        else if (page === 'privacy')
          route = Domains.VW.includes(domainName) ? routePaths.SHORT_PRIVACY_VW : routePaths.PRIVACY_VW;
        else if (page === 'OnlineAccess')
          route = Domains.VW.includes(domainName) ? routePaths.SHORT_ONLINE_ACCESS_VW : routePaths.ONLINE_ACCESS_VW;
        else if (page === 'ach') route = Domains.VW.includes(domainName) ? routePaths.SHORT_ACH_VW : routePaths.ACH_VW;
        else if (page === 'sms') route = Domains.VW.includes(domainName) ? routePaths.SHORT_SMS_VW : routePaths.SMS_VW;
        else if (page === 'autopay')
          route = Domains.VW.includes(domainName) ? routePaths.SHORT_AUTHORIZATION_VW : routePaths.AUTHORIZATION_VW;
        else if (page === 'b2b-representative')
          route = Domains.VW.includes(domainName)
            ? routePaths.SHORT_B2B_REPRESENTATIVE_BUSINESS_VW
            : routePaths.B2B_REPRESENTATIVE_BUSINESS_VW;
        break;
      default:
        route = Domains.AUDI.includes(domainName) ? routePaths.SHORT_AUDI : routePaths.AUDI;
        break;
    }
    return route;
  }

  return (
    <div className='sidebar'>
      <div>
        <ul className='c-media-grid'>
          <li className='c-media-grid__item'>
            <NavLink to={getRoute(country, brand, 'privacy')} className='nav-item sidebar-item privacy navlink-privacy'>
              {i18n.t(country === Countries.Canada ? rk.PRIVACY_POLICY_TITLE : rk.PRIVACYNOTICE_TITLE)}
            </NavLink>
            <hr className='sidebar-hr' />
          </li>
          <li className='c-media-grid__item' style={{ display: 'none' }}>
            <NavLink
              to={getRoute(country, brand, 'b2b-representative')}
              className='nav-item online-access navlink-b2b-representative sidebar-item'
            >
              Representative of a Business
            </NavLink>
            <hr className='sidebar-hr' />
          </li>
          <li className='c-media-grid__item'>
            <NavLink
              to={getRoute(country, brand, 'OnlineAccess')}
              className='nav-item online-access navlink-OnlineAccess sidebar-item'
            >
              {i18n.t(country === Countries.Canada ? rk.USAGE_AGREEMENT_TITLE : rk.ONLINE_ACCESS_TITLE)}
            </NavLink>
            <hr className='sidebar-hr' />
          </li>
          {country === Countries.Canada && (
            <li className='c-media-grid__item'>
              <a
                href={
                  brand === Brands.AUDI
                    ? i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA_AUDI)
                    : i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA_VW)
                }
                className='nav-item sidebar-item'
                target='_blank'
                rel='noopener noreferrer'
              >
                {i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA)}
                <img src={iconimage} alt={i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA)} className='sidebar-image' />
              </a>
              <hr className='sidebar-hr' />
            </li>
          )}
          {country === Countries.USA && (
            <>
              <li className='c-media-grid__item'>
                <NavLink to={getRoute(country, brand, 'ach')} className='nav-item sidebar-item ach navlink-ach'>
                  {i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TITLE)}
                </NavLink>
                <hr className='sidebar-hr' />
              </li>
              <li className='c-media-grid__item'>
                <NavLink
                  to={getRoute(country, brand, 'autopay')}
                  className='nav-item sidebar-item Authorization navlink-autopay'
                >
                  {i18n.t(rk.AUTOMATIC_PAYMENT_PLAN_TITLE)}
                </NavLink>
                <hr className='sidebar-hr' />
              </li>
              <li className='c-media-grid__item'>
                <NavLink to={getRoute(country, brand, 'sms')} className='nav-item sidebar-item sms navlink-sms'>
                  {i18n.t(rk.SMS_TERMSOFSERVICE_TITLE)}
                </NavLink>
                <hr className='sidebar-hr' />
              </li>
              {brand === Brands.AUDI && (
                <li className='c-media-grid__item'>
                  <a
                    href={getRoute(country, brand, 'myAudi')}
                    className='nav-item sidebar-item'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {i18n.t(rk.MYAUDI_LOGIN_TITLE)}
                    <img src={iconimage} alt={i18n.t(rk.MYAUDI_LOGIN_TITLE)} className='sidebar-image' />
                  </a>
                  <hr className='sidebar-hr' />
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  country: CountryType.isRequired,
  brand: BrandType.isRequired,
};
export default SideBar;
